import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory as createHistory } from 'history'

import Home from '../components/Home'

export const history = createHistory()

const AppRouter = () => {
    return (
        <Router history={history}>
            <>

                <section className="hero is-fullheight">
                    <div className="hero-body">
                        <div className="container">
                            <Switch>
                                <Route path='/' component={Home} />
                            </Switch>
                        </div>
                    </div>
                </section>



            </>
        </Router>
    )
}

export default AppRouter
