import React from 'react';
import ReactDOM from 'react-dom';

import 'bulma/css/bulma.css'
import '../src/index.css'
import AppRouter from './router/AppRouter'

ReactDOM.render(
  <AppRouter />,
  document.getElementById('root')
);