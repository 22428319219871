import React, { useState, useEffect } from 'react'
import platform from 'platform'
import FileSaver from 'file-saver';
import { FaWindows, FaApple } from 'react-icons/fa';

function Home() {
    const [selectedOS, setSelectedOS] = useState(undefined)
    const [showDownload, setShowDownload] = useState(false)

    useEffect(() => {
        if (platform.os.family === 'Windows' || platform.os.family === 'MacOS') {
            setSelectedOS(platform.os.family)
            setShowDownload(true)
        }
    }, [])

    const keys = {
        'Windows': 'AnyDesk_Sharp_ICT.exe',
        'MacOS': 'AnyDesk_Sharp_ICT.dmg'
    }

    const handleChange = (event) => {
        setSelectedOS(event.target.value)
    }

    const handleOnDownloadClick = async (event) => {
        FileSaver.saveAs(`files/${keys[selectedOS]}`, keys[selectedOS])
    }

    return (
        <>
            {showDownload ? (<div className="columns is-vcentered">
                <div className="column has-text-right-desktop has-text-centered-mobile has-text-right-tablet">
                    <img alt="sharp logo" src="BeOriginal.svg" width="194px" />
                </div>
                <div className="column is-0"></div>
                <div className="column">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="55 0 300 55" width="150" height="20" className="mb-2">
                        <title>AnyDesk</title>
                        <polygon className="fill-color" points="46.6,0 41.1,5.5 60.4,24.7 41.1,44 46.6,49.4 71.3,24.7 "></polygon>
                        <rect x="7.2" y="7.2" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 24.7138 59.6646)" className="fill-color" width="35" height="35"></rect>
                        <g>
                            <path d="M106.3,43.1l-2.7-8.7H90.3l-2.7,8.7h-8.4L92.2,6.3h9.5l13,36.8H106.3z M101.8,27.9C99.3,20,98,15.5,97.7,14.5 S97.1,12.6,97,12c-0.6,2.1-2.1,7.4-4.7,15.9H101.8z"></path>
                            <path d="M144.5,43.1h-7.6V26.8c0-2-0.4-3.5-1.1-4.5s-1.9-1.5-3.4-1.5c-2.1,0-3.7,0.7-4.6,2.1s-1.4,3.8-1.4,7.1v13.2h-7.6v-28h5.8 l1,3.6h0.4c0.9-1.4,2-2.4,3.5-3.1c1.5-0.7,3.2-1,5.1-1c3.3,0,5.7,0.9,7.4,2.6s2.5,4.3,2.5,7.6V43.1z"></path>
                            <path d="M147.6,15.1h8.4l5.3,15.8c0.5,1.4,0.8,3,0.9,4.9h0.2c0.2-1.7,0.5-3.3,1.1-4.9l5.2-15.8h8.2L165,46.7 c-1.1,2.9-2.6,5.1-4.6,6.6c-2,1.5-4.4,2.2-7.1,2.2c-1.3,0-2.6-0.1-3.9-0.4v-6.1c0.9,0.2,1.9,0.3,3,0.3c1.4,0,2.5-0.4,3.5-1.2 c1-0.8,1.8-2.1,2.4-3.7l0.5-1.4L147.6,15.1z"></path>
                            <path d="M211.8,24.4c0,6-1.7,10.6-5.1,13.9s-8.4,4.8-14.9,4.8h-10.4V6.5h11.5c6,0,10.6,1.6,13.9,4.7 C210.1,14.4,211.8,18.8,211.8,24.4z M203.7,24.6c0-7.9-3.5-11.8-10.4-11.8h-4.1v23.8h3.3C200,36.7,203.7,32.7,203.7,24.6z"></path>
                            <path d="M230.2,43.6c-4.5,0-8-1.2-10.6-3.7c-2.5-2.5-3.8-6-3.8-10.6c0-4.7,1.2-8.3,3.5-10.9c2.3-2.6,5.6-3.8,9.7-3.8 c4,0,7,1.1,9.2,3.4c2.2,2.3,3.3,5.4,3.3,9.3V31h-18.1c0.1,2.2,0.7,3.9,1.9,5.1c1.2,1.2,2.9,1.8,5.1,1.8c1.7,0,3.3-0.2,4.8-0.5 c1.5-0.4,3.1-0.9,4.7-1.7v5.9c-1.3,0.7-2.8,1.2-4.3,1.5C234.2,43.4,232.4,43.6,230.2,43.6z M229.1,20c-1.6,0-2.9,0.5-3.8,1.5 c-0.9,1-1.4,2.5-1.6,4.4h10.7c0-1.9-0.5-3.3-1.5-4.4C232,20.5,230.7,20,229.1,20z"></path>
                            <path d="M267.4,34.8c0,2.9-1,5.1-3,6.6c-2,1.5-5,2.3-9,2.3c-2,0-3.8-0.1-5.2-0.4c-1.4-0.3-2.8-0.7-4-1.2v-6.3 c1.4,0.7,3,1.2,4.8,1.7c1.8,0.5,3.3,0.7,4.7,0.7c2.8,0,4.2-0.8,4.2-2.4c0-0.6-0.2-1.1-0.6-1.5c-0.4-0.4-1-0.8-1.9-1.3 c-0.9-0.5-2.1-1-3.6-1.7c-2.2-0.9-3.7-1.7-4.7-2.5c-1-0.8-1.7-1.6-2.2-2.6c-0.5-1-0.7-2.2-0.7-3.7c0-2.5,1-4.4,2.9-5.8 c1.9-1.4,4.7-2,8.2-2c3.4,0,6.7,0.7,9.8,2.2l-2.3,5.5c-1.4-0.6-2.7-1.1-3.9-1.5c-1.2-0.4-2.5-0.6-3.7-0.6c-2.3,0-3.4,0.6-3.4,1.8 c0,0.7,0.4,1.3,1.1,1.8c0.7,0.5,2.3,1.2,4.8,2.2c2.2,0.9,3.8,1.7,4.8,2.5c1,0.8,1.8,1.7,2.3,2.7C267.1,32.2,267.4,33.4,267.4,34.8z"></path>
                            <path d="M279.5,27.9l3.3-4.3l7.8-8.5h8.6l-11.1,12.1L300,43.1h-8.8l-8.1-11.3l-3.3,2.6v8.7h-7.6v-39h7.6v17.4l-0.4,6.4H279.5z"></path>
                        </g>
                    </svg>
                    <div className="columns is-vcentered">
                        <div className="column pr-0 is-narrow">
                            <button className="button is-primary" onClick={handleOnDownloadClick}>Download</button>
                        </div>
                        <div className="column is-narrow">
                            <div className="field">
                                <div className="control has-icons-left">
                                    <div className="select">
                                        <select value={selectedOS} onChange={handleChange}>
                                            <option value="Windows">Windows</option>
                                            <option value="MacOS">MacOS</option>

                                        </select>
                                    </div>
                                    <span className="icon is-left">
                                        {selectedOS === 'Windows' && <FaWindows size="1.2em" />}
                                        {selectedOS === 'MacOS' && <FaApple size="1.2em" />}

                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>) : (
                <>
                    <p className="has-text-centered">AnyDesk for Sharp ICT is only available on Windows or MacOS.</p>
                </>
            )}
            
        </>
    )
}

export default Home
